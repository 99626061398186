import React from 'react'
import './Button.less'

export default function Button ({ name, onClick, isOn }) {
  return (
    <div
      className={['Button', 'Button--' + name, isOn ? 'is-on' : 'is-off'].join(
        ' '
      )}
      onClick={onClick}
    >
      {name}
    </div>
  )
}
