import React from 'react'
import Video from './Video'
import Button from './Button'
import Snowfall from 'react-snowfall'
import './App.less'

export default function App ({ state }) {
  const data = state.get()
  const [localStream, localScreenStream] = data.localStreams
  return (
    <div>
      <Snowfall style={{ zIndex: 1, opacity: 0.4 }} />
      <Button
        name='screen'
        onClick={addScreenShare}
        isOn={Boolean(localScreenStream)}
      />
      <div className='MuteButtonContainer'>
        <Button
          name='audio'
          onClick={() => state.set('audio', !data.audio)}
          isOn={data.audio}
        />
        <Button
          name='video'
          onClick={() => state.set('video', !data.video)}
          isOn={data.video}
        />
      </div>
      {!data.connected && (
        <div className='VideoContainer'>
          <div className='Waiting'>...waiting for peer</div>
        </div>
      )}
      {localScreenStream && (
        <Video name='localScreen' stream={localScreenStream} muted />
      )}
      {(data.remoteStreams || [])
        .slice()
        .reverse()
        .map((stream, i) => {
          const name = `stream-${i}`
          return <Video key={name} name={name} stream={stream} />
        })}
      {localStream && (
        <Video name='local' stream={localStream} muted privacy={data} />
      )}
    </div>
  )

  function addScreenShare () {
    return (
      !localScreenStream &&
      navigator.mediaDevices
        .getDisplayMedia({
          video: true,
          audio: false
        })
        .then(stream => {
          state.emit('addLocalStream', stream)
        })
    )
  }
}
