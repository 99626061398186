import React from 'react'
import ReactDOM from 'react-dom'
import App from '../components/App'

export default function render (rootEl, state) {
  return new Promise(renderApp).then(() => {
    return state.on('change', () => renderApp())
  })

  function renderApp (cb) {
    ReactDOM.render(<App state={state} />, rootEl, cb)
  }
}
