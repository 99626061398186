import adapter from 'webrtc-adapter'
import log from './log'

// Taken from https://github.com/webrtc/samples/blob/gh-pages/src/content/peerconnection/bandwidth/js/main.js
export default function updateBandwidthRestriction (sdp, bandwidth) {
  if (!bandwidth) {
    log.trace('removing bandwidth restriction')
    return removeBandwidthRestriction(sdp)
  }

  let modifier = 'AS'
  if (adapter.browserDetails.browser === 'firefox') {
    bandwidth = (bandwidth >>> 0) * 1000
    modifier = 'TIAS'
  }
  const insert = 'b=' + modifier + ':' + bandwidth + '\r\n'
  if (sdp.indexOf('b=' + modifier + ':') === -1) {
    log.trace('inserting ' + insert)
    // insert b= after c= line.
    sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\n' + insert)
    log.trace('inserting ' + insert)
  } else {
    sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), insert)
    log.trace('inserting ' + insert)
  }
  return sdp
}

function removeBandwidthRestriction (sdp) {
  return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '')
}
