import React, { useRef, useEffect } from 'react'
import './Video.less'

export default function Video ({ stream, name, muted, privacy }) {
  const ref = useRef()
  useEffect(() => {
    if ('srcObject' in ref.current) {
      ref.current.srcObject = stream
    } else {
      ref.current.src = window.URL.createObjectURL(stream)
    }
  }, [stream])

  if (privacy) {
    stream.getTracks().forEach(track => {
      track.enabled = privacy[track.kind]
    })
  }
  return (
    <div className='VideoContainer'>
      <video id={name} className='Video' ref={ref} autoPlay muted={muted} />
    </div>
  )
}
