import niceware from 'niceware'

export default function getRoomId () {
  const roomId =
    window.location.search || '?' + niceware.generatePassphrase(6).join('-')
  if (!window.location.search) {
    if (window.history.pushState) {
      window.history.pushState(null, null, roomId)
    } else {
      window.location.search = roomId
    }
  }
  return { roomId }
}
