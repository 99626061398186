import createEmitter from 'on-emit'

export default function createState (state = {}) {
  const emitter = createEmitter()

  return {
    get: () => state,
    set: (key, value) => {
      state = { ...state, [key]: value }
      emitter.emit('change', state)
    },
    next: fn => {
      state = fn(state)
      emitter.emit('change', state)
    },
    ...emitter
  }
}
