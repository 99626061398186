import Peer from 'simple-peer'
import createSocketClient from 'socket.io-client'
import updateBandwidthRestriction from './updateBandwidthRestriction'
import log from './log'
const socket = createSocketClient(window.location.href.replace(/http/, 'ws'))

export default function connect (roomId, state) {
  socket.emit('signup', { roomId })
  socket.on('login', init)

  function init ({ peerId }) {
    socket.off('login', init)
    log.info(`Peer ${peerId} logged in`)
    socket.emit('ping', { roomId })

    socket.once('pong', requestChat)

    socket.once('chat', acceptChat)

    function acceptChat ({ peerId: otherPeerId }) {
      log.info(`Recieved chat request from ${otherPeerId}`)
      // the last person to join the room should be the initiator
      createPeer(true)
    }

    function requestChat ({ peerId: otherPeerId }) {
      log.info(`Recieved pong from ${otherPeerId}`)
      // the last person to join the room should be the initiator
      createPeer(false)

      socket.emit('chat', { roomId })
    }

    function createPeer (initiator) {
      const peer = new Peer({
        initiator,
        sdpTransform: sdp => {
          return updateBandwidthRestriction(sdp, 120)
        }
      })

      socket.on('signal', registerSignal)

      // Broadcast signal
      peer.on('signal', broadcastSignal)

      peer.once('connect', data => {
        state.set('connected', true)
        log.info('CONNECTED')
        socket.off('pong', requestChat)
        socket.off('chat', acceptChat)
        peer.on('data', data => {
          log.info('Recieved data', String(data), data)
        })
        peer.send('whatever' + Math.random())
        const { localStreams } = state.get()
        for (const localStream of localStreams) {
          peer.addStream(localStream)
        }
        state.on('addLocalStream', (type, stream) => peer.addStream(stream))
      })

      peer.on('stream', function addRemoteMedia (remoteStream) {
        state.emit('addRemoteStream', remoteStream)
      })

      peer.on('error', err => {
        log.error(err)
      })

      peer.once('close', destroy)

      function destroy () {
        log.info('CONNECTION ENDED')
        state.next(state => {
          return {
            ...state,
            remoteStreams: [],
            connected: false
          }
        })
        peer.off('signal', broadcastSignal)
        socket.off('signal', registerSignal)
        peer.destroy()
        init({ peerId })
      }

      function broadcastSignal (signal) {
        log.trace('Sending signal', signal)
        socket.emit('signal', { peerId, roomId, initiator, signal })
      }

      function registerSignal (data) {
        log.trace('Recieving signal', data)
        peer.signal(data.signal)
      }

      return peer
    }
  }
}
