export default function registerStreams (state) {
  state.on('addLocalStream', (type, stream) => {
    const { localStreams = [] } = state.get()
    state.set('localStreams', localStreams.concat(stream))
  })

  state.on('addRemoteStream', (type, stream) => {
    const { remoteStreams = [] } = state.get()
    state.set('remoteStreams', remoteStreams.concat(stream))
  })

  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then(stream => {
      const { localStreams = [] } = state.get()
      state.set('localStreams', localStreams.concat(stream))
    })
}
