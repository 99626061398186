import createState from './lib/state'
import getRoomId from './lib/getRoomId'
import log from './lib/log'
import render from './lib/render'
import connect from './lib/connect'
import registerStreams from './lib/registerStreams'


const initialState = {
  video: true,
  audio: true,
  localStreams: [],
  remoteStreams: []
}
const { roomId } = getRoomId()
const state = createState({ ...initialState })

registerStreams(state)

const rootEl = document.querySelector('#root')
render(rootEl, state)
  .then(() => connect(roomId, state))
  .catch(log.error)
